import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';

// Components & UI
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';
import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { PrescriptionStatuses } from 'components/prescription-statuses';
import { PrescriptionDetails } from 'components/prescription-details';
import './prescription-card-mobile.style.scss';

// Prescription Components & Utils
import { PrescriptionCardDisplay } from './types';
import PrescriptionCardIcon from './prescription-card.icon';
import { canRxBeCancelled, isRxWhiteCard } from 'util/prescription';
import { PrescriptionStatusBox } from 'components/prescription-status-box';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

const PrescriptionCardMobile: React.FC<PrescriptionCardDisplay> = ({
    isCollapsed,
    orderStatus,
    orderSubStatus,
    fullPayload,
    rxAutoRefillEnabled,
    handleAutoRefillInCard,
    autoRefillToggleBusy,
    rxNumber,
    prescriptionName,
    handleInfoClick,
    isOrderProcessing,
    details,
    rxDisplayStatuses,
    orderDisplayStatuses,
    orderSubStatusLoading,
    refillsLeft,
    rxNextRefillStatuses,
    rxCardFootNote,
    profileObject,
    accountHasInsurance,
    price,
    ctas,
    setIsRxBusy,
    isRxBusy,
    isAddingToCart,
    className,
    isOrderedStatus,
    isOrderStatusVisible,
    isOrderSubStatusProcessing,
    handleCancelRxClick
}) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const orderStatusClassName = orderStatus.toLowerCase().replace(/ /g, '_');
    const classes = classNames('prescription-card-v2 mobile', orderStatusClassName, className, {
        collapsed: isCollapsed
    });
    const nexRefillContainerClasses = classNames('prescription-card__rx-refills-container', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue && isCollapsed
    });
    const prescriptionCardRefillsOrderClasses = classNames('prescription-card__refills-order', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue && !isCollapsed
    });

    let mobileDetails = details;

    if (isCollapsed) {
        mobileDetails = [
            ...[details[1]],
            {
                detail: (
                    <Button
                        className="btn-text-blue-light btn-text"
                        type="button"
                        variant="link"
                        label={t('components.prescriptionCard.drugInfo')}
                        onClick={handleInfoClick}
                    />
                )
            }
        ];
    }

    return (
        <div className={classes}>
            {/* Content Section */}
            <div className="prescription-card__content-container">
                <div className="prescription-card__content-details">
                    {/* Order Status */}
                    <PrescriptionStatusBox statuses={rxDisplayStatuses} />

                    {/* Prescription Name */}
                    <div className="prescription-card__title-info-container">
                        {/* Icon */}
                        {!isCollapsed && (
                            <div className="prescription-card__icon_container">
                                <CSSTransition
                                    nodeRef={nodeRef}
                                    in={!!orderSubStatus.orderStatus}
                                    timeout={4000}
                                    classNames="prescription-card-icon"
                                >
                                    <div
                                        ref={nodeRef}
                                        className={'prescription-card__icon-badge-new'}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {}}
                                    >
                                        <PrescriptionCardIcon
                                            variant={orderStatus}
                                            orderSubStatus={orderSubStatus.orderStatus}
                                            t={t}
                                        />
                                    </div>
                                </CSSTransition>
                            </div>
                        )}

                        <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                            {prescriptionName}
                        </h4>
                    </div>

                    {/* RX Number & Details */}
                    <div className="prescription-card__rx-details-container">
                        <PrescriptionDetails details={mobileDetails} expanded={false} />
                    </div>

                    {/* Refill & Order Statuses Mobile */}
                    <div className={prescriptionCardRefillsOrderClasses}>
                        {/* Refill Status */}
                        {!isRxWhiteCard(orderStatus) && (
                            <div
                                className={classNames('prescription-card__refills', {
                                    'prescription-card__refills--empty': refillsLeft === 0
                                })}
                            >
                                {!isCollapsed && (
                                    <div className="prescription-card__refills-label">
                                        {refillsLeft > 0 && (
                                            <span>{t('components.prescriptionCard.orderRefillAva')}:</span>
                                        )}
                                    </div>
                                )}
                                <div>
                                    <span className={refillsLeft === 1 ? 'text-warning' : ''}>{refillsLeft}</span>{' '}
                                    <span className={refillsLeft === 1 ? 'text-warning' : ''}>
                                        {t('components.prescriptionCard.refillsLeftTitle')}
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* Next Refill */}
                        <div className={nexRefillContainerClasses}>
                            <div>
                                {rxNextRefillStatuses.length > 0 && (
                                    <div className="prescription-card__next-refill">
                                        <div className="prescription-card__details-status-headings">
                                            {rxNextRefillStatuses[0]?.isRefillDue ? (
                                                <>
                                                    {!isCollapsed && (
                                                        <div className="icon">
                                                            <CircleInfo />
                                                        </div>
                                                    )}
                                                    {t(
                                                        `components.prescriptionCard.${
                                                            isCollapsed ? 'orderRefillDueCollapsed' : 'orderRefillDue'
                                                        }`
                                                    )}
                                                </>
                                            ) : (
                                                t(
                                                    `components.prescriptionCard.${
                                                        isCollapsed ? 'orderNextRefillCollapsed' : 'orderNextRefill'
                                                    }`
                                                )
                                            )}
                                        </div>
                                        <PrescriptionStatuses statuses={rxNextRefillStatuses} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Last Refill */}
                        {!!fullPayload.lastFillDate && (orderStatus === 'EXPIRED' || orderStatus === 'OUT_OF_REFILLS') && (
                            <div className="prescription-card__rx-refills-container">
                                <div className="prescription-card__next-refill">
                                    <div className="prescription-card__refills-label">
                                        <span>
                                            {t(
                                                `components.prescriptionCard.${
                                                    isCollapsed ? 'orderLastRefillCollapsed' : 'orderLastFilled'
                                                }`
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <strong>{fullPayload.lastFillDate}</strong>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Order Statuses */}
                        {isOrderStatusVisible && (
                            <div className="prescription-card__rx-order-statuses">
                                {orderDisplayStatuses.length > 0 && (
                                    <div className="prescription-card__rx-statuses-container">
                                        <div className="prescription-card__rx-statuses">
                                            {orderSubStatusLoading ? (
                                                <>
                                                    <span className="prescription-card__status">
                                                        {t('components.prescriptionCard.fetchingOrderStatus')}
                                                        <Spinner
                                                            isVisible
                                                            t={t}
                                                            fullOverlay={false}
                                                            isSmall
                                                            className="prescription-card__loading-spinner"
                                                        />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {orderSubStatus.orderStatus !== '' && (
                                                        <>
                                                            <span className="prescription-card__status-label">
                                                                {t('components.prescriptionCard.orderStatusLabel')}
                                                            </span>
                                                            <ul className="prescription-card__status-list prescription-card__order-status-list">
                                                                <li className="prescription-card__status">
                                                                    {orderSubStatus.orderStatus}
                                                                </li>
                                                                {orderSubStatus.subStatus && (
                                                                    <li
                                                                        className={`prescription-card__order-sub-status ${
                                                                            isOrderSubStatusProcessing
                                                                                ? 'processing'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {orderSubStatus.subStatus}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {rxCardFootNote && (
                                    <div className="prescription-card__expired-container">
                                        <div className="prescription-card__rx-expired-note">
                                            <span className="text-dark">{rxCardFootNote}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Cart & Autorefill */}
            <div className="prescription-card__cart_container">
                {ENABLE_AUTO_REFILL &&
                    !isCollapsed &&
                    fullPayload.autorefillPlanEligible &&
                    fullPayload.autoRefillEligible !== null && (
                        <AutoRefillToggle
                            checked={rxAutoRefillEnabled}
                            onChange={(val: boolean) => handleAutoRefillInCard(val)}
                            t={t}
                            rxEligible={fullPayload.autoRefillEligible}
                            isBusy={autoRefillToggleBusy}
                        />
                    )}
                {ctas?.map((cta, idx) => (
                    <Button
                        className="sm-full"
                        key={`prescription-card-cta-button-${idx}`}
                        {...cta}
                        type={'button'}
                        async
                        onClick={() => {
                            setIsRxBusy(true);
                            cta.onClick();
                        }}
                        isBusy={isRxBusy && isAddingToCart}
                        disabled={isAddingToCart || cta.disabled}
                        dataGALocation="MedicineCabinet"
                        variant={cta.disabled ? 'outline' : 'outline-primary'}
                    />
                ))}
            </div>

            {/* CTAs */}
            {!isCollapsed && (
                <div className="prescription-card__cta_container">
                    <Button
                        className="btn-text-blue-light btn-text"
                        type="button"
                        variant="link"
                        label={t('components.prescriptionCard.drugInfo')}
                        onClick={handleInfoClick}
                    />
                    {isOrderedStatus && (
                        <>
                            <span className="divider">|</span>
                            <Button
                                className="btn-text-blue-light btn-text"
                                type="button"
                                variant={'link'}
                                label={t('components.prescriptionCard.trackOrder')}
                                onClick={handleInfoClick}
                            />
                        </>
                    )}
                    <span className="divider">|</span>
                    <Button
                        className="btn-text-blue-light btn-text"
                        type="button"
                        variant="link"
                        label={t('components.prescriptionCard.orderHistory')}
                        onClick={() => {
                            navigate('/secure/order/history');
                        }}
                    />
                    {canRxBeCancelled(fullPayload) && (
                        <>
                            <span className="divider">|</span>
                            <Button
                                className="btn-text-blue-light btn-text"
                                type="button"
                                variant="link"
                                label={t('components.prescriptionCard.remove')}
                                onClick={handleCancelRxClick}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default PrescriptionCardMobile;
