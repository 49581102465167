import React from 'react';
import { PrescriptionStatusesProps } from './types';

export const PrescriptionStatuses: React.FC<PrescriptionStatusesProps> = ({ statuses }) => {
    return (
        <ul className="prescription-card__status-list">
            {statuses.map(
                ({ status, primary }, index) =>
                    status && (
                        <li key={index} className="prescription-card__status">
                            {status}
                        </li>
                    )
            )}
        </ul>
    );
};
