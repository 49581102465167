import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import './sidebar-column.component.scss';

export interface SidebarColumnProps {
    children?: React.ReactNode;
    className?: string;
}

const Sidebar: FC<SidebarColumnProps> = ({ children, className = '' }: SidebarColumnProps) => {
    return (
        <Row className={`sidebar-column__wrapper ${className}`}>
            <Col className="d-none d-lg-block">
                <div className="sidebar-column">{children}</div>
            </Col>
        </Row>
    );
};

export default Sidebar;
