import React from 'react';
import { PrescriptionDetailsProps } from './types';

export const PrescriptionDetails: React.FC<PrescriptionDetailsProps> = ({ details, expanded = false }) => {
    return (
        <ul className="prescription-card__details-list">
            {details.map(
                ({ detail, primary }, index) =>
                    detail && (
                        <li
                            key={index}
                            className={`prescription-card__detail ${primary || expanded ? 'is-primary' : ''}`}
                        >
                            {detail}
                        </li>
                    )
            )}
        </ul>
    );
};
