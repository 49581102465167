import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// UI Kit & Components
import EmptyCart from 'components/new-medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/new-medicine-cabinet-cart/health-profile/health-profile.component';
import PaymentMethod from 'components/new-medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/new-medicine-cabinet-cart/prescription-information/prescription-information.component';
import ShippingAddress from 'components/new-medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/new-medicine-cabinet-cart/shipping-method/shipping-method.component';
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';

// Cart
import {
    cartSelector,
    cartItemsTotalSelector,
    cartOrderBillShipMethodSelector,
    cartOrderBillShipSelector,
    cartOrderPaymentCardSelector,
    cartSubtotalSelector
} from 'state/cart/cart.selectors';

// Account
import { accountCreditCardsSelector } from 'state/account/account.selectors';
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';

import { accountHasInsuranceSelector, accountPlansSelector } from 'state/account/account.selectors';

import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import './new-medicine-cabinet-cart.style.scss';
import { NewCartTotal } from './new-cart-total/new-total-cart.component';

const NewMedicineCabinetCart: React.FC = () => {
    const { t } = useTranslation();

    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);
    const cartItemsTotal = useSelector(cartItemsTotalSelector) || 0;
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const estimatedSubtotal = useSelector(cartSubtotalSelector);
    const cartObject = useSelector(cartSelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);

    return (
        <SidebarColumn className="new-medicine-cabinet-cart">
            <div className="new-medicine-cabinet-cart__header">
                <CartIcon className={'header-icon-container'} />
                <h3>{t('components.medicineCabinetCart.title')}</h3>
            </div>

            {cartItemsTotal > 0 ? (
                <>
                    <ShippingAddress />
                    <ShippingMethod />
                    <PaymentMethod
                        addNewPaymentButtonLabel={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                        buttonVariant="text-blue"
                        selectedPaymentMethod={selectedPaymentMethod}
                        paymentData={allPaymentData || []}
                        showSelectCardRadioInput
                        showSetDefaultLink
                        orderBillShip={orderBillShip!}
                    />
                    <HealthProfile />
                    <PrescriptionInformation
                        cartObject={cartObject}
                        isInsuranceAccount={accountHasInsurance}
                        prescriptions={prescriptionsObject}
                        accountPlans={accountPlans}
                    />
                    <NewCartTotal
                        cartObject={cartObject}
                        prescriptionsObject={prescriptionsObject}
                        isUnknownPrice={false}
                        initialOrderPrice={estimatedSubtotal}
                        currentShippingPrice={shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0}
                        isInsuranceAccount={false}
                        // DRX-1660: As the new Cart has two new functionallities, i decide to create a new component
                        // to mantain the compability
                    />
                </>
            ) : (
                <EmptyCart
                    title={t('components.medicineCabinetCart.empty.title')}
                    body={t('components.medicineCabinetCart.empty.body')}
                />
            )}
        </SidebarColumn>
    );
};

export default NewMedicineCabinetCart;
