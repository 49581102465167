import React from 'react';
import { PrescriptionStatusesProps } from './types';

export const PrescriptionStatusBox: React.FC<PrescriptionStatusesProps> = ({ statuses }) => {
    const statusesPrimary = statuses.filter((status) => {
        return status.primary;
    });

    const status = statusesPrimary.length > 0 ? statusesPrimary[0].status : null;

    if (!status) {
        return null;
    }

    return <div className="prescription-card__status-box">{status}</div>;
};
