import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { sortBy } from 'lodash';
import classNames from 'classnames';

import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';

// Components, Helpers & UI Kit
import Button from 'ui-kit/button/button';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import BirdiModalContent, { OverflowModal } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import { prescriptionPayloadToProps } from './payload-to-props';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { formatPrice } from 'schema/price.schema';
import './prescriptions-list.style.scss';

// Prescriptions & Services
import PrescriptionCard from './prescriptions-card/prescriptions-card.component';
import {
    medicineCabinetFilteredRxsForStatusSelector,
    medicineCabinetPopulatedSelector,
    medicineCabinetPrescriptionsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import {
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountPlansSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetToggleAutoRefillAllRxs
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { hasAdjudicatedPrice, processCart } from 'util/cart';
import { cartItemsSelector } from 'state/cart/cart.selectors';
import { startCartRoutine, updateRefillLinesRoutine } from 'state/cart/cart.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import PrescriptionsCards from './prescriptions-cards/prescriptions-cards.component';
import { accountFetchPlansRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { isRxExpired } from 'util/prescription';
import { PrescriptionCardProps } from './prescriptions-card/types';
import { PrescriptionObjectPayload, ToggleAutoFillRequest } from 'state/medicine-cabinet/medicine-cabinet.services';
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

interface PrescriptionsListProps {
    pillImage: GatsbyTypes.File;
}

const PrescriptionsList: React.FC<PrescriptionsListProps> = ({ pillImage }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const cartItemsObject = useSelector(cartItemsSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const medicineCabinetPopulated = useSelector(medicineCabinetPopulatedSelector);
    const medicineCabinetFilteredRxsForStatus = useSelector(medicineCabinetFilteredRxsForStatusSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const profileObject = useSelector(accountProfileSelector);
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isAddingToCart, setIsAddingToCart] = useState(true);
    const [rxsLoaded, setRxsLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [filteredRxs, setFilteredRxs] = useState<PrescriptionCardProps[] | null>(null);
    const [orderedRxs, setOrderedRxs] = useState<PrescriptionCardProps[] | null>(null);
    const [isAllStatusesLoaded, setIsAllStatusesLoaded] = useState<boolean>(false);

    const buttonLabel = isCollapsed
        ? t('components.prescriptionsList.mobile.ctas.expandCards')
        : t('components.prescriptionsList.mobile.ctas.collapseCards');

    useEffect(() => {
        const prescriptionProps: PrescriptionCardProps[] = prescriptions.map((prescription) =>
            prescriptionPayloadToProps(prescription, t, accountHasInsurance, () => {
                handleAddToCartClick(prescription.rxNumber);
            })
        );

        const orderedPrescriptionProps = sortBy(prescriptionProps, 'sortOrder');

        setOrderedRxs(orderedPrescriptionProps);
        setFilteredRxs(orderedPrescriptionProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriptions]);

    useEffect(() => {
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true, onSuccess: () => setRxsLoaded(true) }));
        if (!allPaymentData) {
            dispatch(accountGetAllCreditCardsRoutine.trigger()); // needed to init Cart and New/Transfer workflows
        }
        if (!accountPlans || accountPlans.length === 0) {
            dispatch(accountFetchPlansRoutine());
        }
        setIsAddingToCart(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (medicineCabinetPopulated) {
            medicineCabinetFilteredRxsForStatus
                .map((rx) => rx.rxNumber)
                .forEach((rxNumber) => dispatch(medicineCabinetGetStatusForRx.trigger({ rxNumber })));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicineCabinetPopulated]);

    useEffect(() => {
        if (medicineCabinetFilteredRxsForStatus.length === 0) {
            return;
        }

        const areAllStatusesLoaded = areAllRxsStatusFound(
            medicineCabinetFilteredRxsForStatus,
            (rx) => rx.orderStatus !== null
        );

        setIsAllStatusesLoaded(areAllStatusesLoaded);
    }, [medicineCabinetFilteredRxsForStatus]);

    useEffect(() => {
        // Get discount drug prices after the profile and all prescriptions are
        // loaded.
        if (profileObject !== undefined && prescriptions.length > 0) {
            let birdiPricePrescriptions: any[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.
            if (accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = prescriptions.filter(function (rx) {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!accountHasInsurance) {
                    birdiPricePrescriptions = prescriptions.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'MedCabinet'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileObject?.epostPatientNum, prescriptions.length]);

    const handleAddToCartClick = (rxNumber: string) => {
        setIsAddingToCart(true);
        const currentPrescription = prescriptions.find((obj: any) => {
            return obj.rxNumber === rxNumber;
        });
        let cartRoutineSwitch;
        let rxInCart = false;
        if (!cartItemsObject || cartItemsObject?.length === 0) {
            cartRoutineSwitch = startCartRoutine;
        } else {
            cartRoutineSwitch = updateRefillLinesRoutine;
            // Make sure item isn't already in cart, due to super-fast clicking. TODO: move to saga
            //cast alreadyInCart = orderObject.refillRxs.find(action.payload.rxNumber)
            if (cartItemsObject) {
                rxInCart = cartItemsObject.some((cartItem) => cartItem.rxNumber === rxNumber);
            }
        }

        if (!rxInCart) {
            dispatch(
                cartRoutineSwitch.trigger({
                    rxNumbers: [rxNumber],
                    onFailure: (data: any) => {
                        setIsAddingToCart(false);
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onSuccess: (data: any) => {
                        const itemCount = data.refillRxs.length;
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));

                        const extendedCart = processCart(
                            data,
                            accountHasInsurance,
                            prescriptions,
                            drugDiscountPrices,
                            accountPlans
                        );
                        const refillRxItem = extendedCart.extendedRefillRxs?.find((obj: any) => {
                            return obj.rxNumber === rxNumber;
                        });
                        TrackCheckoutStep({
                            stepName: 'add',
                            step: '1',
                            cart: extendedCart,
                            prescriptions: prescriptions,
                            t: t,
                            shippingCost: '0',
                            accountHasInsurance: accountHasInsurance
                        });
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                contentClassName: 'overflow-modal',
                                bodyContent: (
                                    <OverflowModal
                                        title={t('modals.updateCart.addedToCart')}
                                        text={t('modals.updateCart.addedContentsMessage', {
                                            drug: currentPrescription?.dispensedProductName,
                                            price: !hasAdjudicatedPrice(refillRxItem, currentPrescription)
                                                ? ''
                                                : `(${formatPrice(refillRxItem?.patientCopay)})`,
                                            itemCount: `<strong>${itemCount}</strong>`,
                                            count: itemCount
                                        })}
                                        warningText={
                                            !hasAdjudicatedPrice(refillRxItem, currentPrescription)
                                                ? t(
                                                      refillRxItem?.showDisclaimer
                                                          ? refillRxItem.disclaimerTranslationKey
                                                              ? refillRxItem.disclaimerTranslationKey
                                                              : 'pages.cart.rxItemNotCoveredWhenAddedErrorMessage'
                                                          : accountHasInsurance
                                                          ? 'pages.cart.rxItemErrorMessage'
                                                          : 'pages.cart.rxItemErrorMessageDiscount'
                                                  )
                                                : ''
                                        }
                                        image={pillImage}
                                        internalCtas={[
                                            {
                                                label: t('modals.updateCart.labels.addMore'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            },
                                            {
                                                label: t('modals.updateCart.labels.viewCart'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    navigate('/secure/cart');
                                                }
                                            }
                                        ]}
                                    />
                                ),
                                ctas: []
                            })
                        );
                        setIsAddingToCart(false);
                    }
                })
            );
        } else {
            setIsAddingToCart(false);
        }
    };

    const handleAutoRefillToggle = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            const data: ToggleAutoFillRequest[] = rxNumbers.map((rx) => {
                return { RxNumber: rx, AutoFillToggle: autoRefillEnabled };
            });
            dispatch(
                medicineCabinetToggleAutoRefillAllRxs.trigger({
                    rxNumbers: data,
                    onSuccess: () => {
                        dispatch(closeModal({}));
                    },
                    onFailure: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'MedicineCabinetTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'MedicineCabinetTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(rxNumbers, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal]
    );

    const handleTabItemClick = (tab: string) => {
        if (!isAllStatusesLoaded) {
            return;
        }
        setActiveTab(tab);
        setFilteredRxs(handleFilterPrescriptions(orderedRxs, tab));
    };

    const handleFilterPrescriptions = (
        prescriptions: PrescriptionCardProps[] | null,
        tab: string
    ): PrescriptionCardProps[] | null => {
        if (prescriptions) {
            switch (tab) {
                case 'all':
                    return prescriptions;
                case 'auto-refills':
                    return prescriptions.filter(
                        (prescription) =>
                            prescription.fullPayload.autoRefillEnabled === true &&
                            prescription.fullPayload.autoRefillEligible === true
                    );
                case 'expired':
                    return prescriptions.filter(
                        (prescription) =>
                            prescription.fullPayload.webEligibilityStatus === 'AUTH_REQ' &&
                            isRxExpired(prescription.fullPayload.rxExpirationDate)
                    );
                default:
                    return prescriptions;
            }
        }

        return null;
    };

    const areAllRxsStatusFound = (
        items: PrescriptionObjectPayload[],
        predicate: (item: PrescriptionObjectPayload) => boolean
    ): boolean => items.every(predicate);

    return (
        <div className="prescription-list-v2">
            <div className="prescription-list__tabs">
                <ul className="tab-list" role="tablist">
                    <li
                        className={classNames('tab-item', activeTab === 'all' ? 'active' : 'inactive')}
                        role="tab"
                        aria-hidden="true"
                        onClick={() => handleTabItemClick('all')}
                    >
                        {t('components.prescriptionsList.mobile.tabs.allPrescriptions')}
                    </li>
                    {prescriptions.length > 0 && (
                        <>
                            <li
                                className={classNames('tab-item', activeTab === 'auto-refills' ? 'active' : 'inactive')}
                                role="tab"
                                aria-hidden="true"
                                onClick={() => handleTabItemClick('auto-refills')}
                            >
                                {t('components.prescriptionsList.mobile.tabs.autoRefills')}
                            </li>
                            <li
                                className={classNames('tab-item', activeTab === 'expired' ? 'active' : 'inactive')}
                                role="tab"
                                aria-hidden="true"
                                onClick={() => handleTabItemClick('expired')}
                            >
                                {t('components.prescriptionsList.mobile.tabs.expired')}
                            </li>
                        </>
                    )}
                </ul>
                <div className="tab-slider" role="presentation" />
            </div>
            <div className="prescription-list__cta-toggle">
                <Button
                    label={buttonLabel}
                    type={'button'}
                    variant="text-blue"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                />
            </div>
            {prescriptions.length > 0 && filteredRxs ? (
                <>
                    <LoadingMessage
                        isVisible={!rxsLoaded && !isAllStatusesLoaded}
                        text={t('pages.medicineCabinet.loading')}
                    />
                    <PrescriptionsCards isCollapsed={isCollapsed}>
                        {isAllStatusesLoaded && rxsLoaded && filteredRxs && filteredRxs.length > 0 ? (
                            filteredRxs.map((prescription, index) => (
                                <PrescriptionCard
                                    key={`medicine-cabinet-rx-card-v2-${prescription.rxNumber}-${index}`}
                                    isAddingToCart={isAddingToCart}
                                    isCollapsed={isCollapsed}
                                    {...prescription}
                                    autoRefillToggle={handleShowAutoRefillToggleModal}
                                />
                            ))
                        ) : rxsLoaded &&
                          isAllStatusesLoaded &&
                          filteredRxs?.length === 0 &&
                          activeTab === 'auto-refills' ? (
                            <>
                                <div>{t('components.prescriptionsList.empty.autoRefills')}</div>
                                <Button
                                    chevron="right"
                                    type="button"
                                    label={t('components.dashboardCtaBlock.titles.transferPre')}
                                    variant={'text-blue'}
                                    onClick={() => {
                                        resetForm();
                                        setPrescriptionFlowType({ flowType: 'Transfer' });
                                        navigate('/secure/prescription');
                                    }}
                                />
                            </>
                        ) : rxsLoaded && isAllStatusesLoaded && filteredRxs?.length === 0 && activeTab === 'expired' ? (
                            <>
                                <div>{t('components.prescriptionsList.empty.expired')}</div>
                                <Button
                                    chevron="right"
                                    type="button"
                                    label={t('components.dashboardCtaBlock.titles.transferPre')}
                                    variant={'text-blue'}
                                    onClick={() => {
                                        resetForm();
                                        setPrescriptionFlowType({ flowType: 'Transfer' });
                                        navigate('/secure/prescription');
                                    }}
                                />
                            </>
                        ) : (
                            rxsLoaded &&
                            isAllStatusesLoaded &&
                            filteredRxs?.length === 0 &&
                            activeTab === 'all' && (
                                <>
                                    <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                                    <Button
                                        chevron="right"
                                        type="button"
                                        label={t('components.dashboardCtaBlock.titles.transferPre')}
                                        variant={'text-blue'}
                                        onClick={() => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        }}
                                    />
                                </>
                            )
                        )}
                    </PrescriptionsCards>
                </>
            ) : (
                prescriptions.length === 0 && (
                    <>
                        <LoadingMessage
                            isVisible={!rxsLoaded && !isAllStatusesLoaded}
                            text={t('pages.medicineCabinet.loading')}
                        />
                        {rxsLoaded && (
                            <>
                                <div>{t('components.prescriptionsList.empty.allPrescriptions')}</div>
                                <Button
                                    chevron="right"
                                    type="button"
                                    label={t('components.dashboardCtaBlock.titles.transferPre')}
                                    variant={'text-blue'}
                                    onClick={() => {
                                        resetForm();
                                        setPrescriptionFlowType({ flowType: 'Transfer' });
                                        navigate('/secure/prescription');
                                    }}
                                />
                            </>
                        )}
                    </>
                )
            )}
        </div>
    );
};

export default PrescriptionsList;
