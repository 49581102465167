import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'ui-kit/button/button';
import InfoIcon from 'ui-kit/icons/info/circle-info-icon';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';

import './auto-refill-banner.style.scss';

const AutoRefillBanner = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    return (
        <div className="auto-refill-learn-more d-flex flex-row">
            <div className="auto-refill-learn-more-info-icon">
                <InfoIcon />
            </div>
            <div className="auto-refill-learn-more-text">
                <h3 className="text-dark">{t('pages.medicineCabinet.autoRefillLearnTitle')}</h3>
                <h4 className="text-dark d-none d-md-inline-block">
                    {t('pages.medicineCabinet.autoRefillLearnBody')}{' '}
                    <Button
                        onClick={handleAutoRefillModal}
                        className="p-0"
                        variant="text"
                        label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                        type="button"
                    />
                    .
                </h4>
                <Button
                    onClick={handleAutoRefillModal}
                    className="arrow d-md-none pl-0 py-0 link d-flex btn-text-blue"
                    variant="arrow"
                    chevron="right"
                    label={t('pages.medicineCabinet.autoRefillLearnMoreLinkMobile')}
                    type="button"
                />
            </div>
        </div>
    );
};

export default AutoRefillBanner;
