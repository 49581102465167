import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { PageProps, graphql, navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';

// Components & UI
import PageSection from 'ui-kit/page-section/page-section';
import NewMedicineCabinetLayout from 'components/layouts/new-medicine-cabinet/new-medicine-cabinet.layout';
import PrescriptionsList from 'components/prescriptions-list-v2/prescriptions-list.component';
import NewMedicineCabinetCart from 'components/new-medicine-cabinet-cart/new-medicine-cabinet-cart';
import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import AccountBalance from 'components/account-balance/account-balance.component';

// Redux & Services
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';
import { accountProfileSelector } from 'state/account/account.selectors';
import { medicineCabinetAutoRefillPlanEligibleSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import 'components/prescriptions-list/PrescriptionList.style.scss';

interface NewMedicineCabinetProps extends PageProps<GatsbyTypes.MedicineCabinetDataQuery> {}
const NewMedicineCabinet: React.FC<NewMedicineCabinetProps> = ({ data }) => {
    const { t } = useTranslation();
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const profileObject = useSelector(accountProfileSelector);
    const displayAutoRefillBanner = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const pillImage = data.successModalPillImage;

    const userName = profileObject ? profileObject.patientFirstName : '';
    return (
        <NewMedicineCabinetLayout greetingMessage={`${t('pages.medicineCabinet.greetingMessageDefault')} ${userName}`}>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={8}>
                        <DashboardCtaBlock
                            icon={'plane'}
                            title={t('components.dashboardCtaBlock.titles.transferPre')}
                            bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                            ctas={[
                                {
                                    label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                    variant: 'dark',
                                    dataGALocation: 'MedicineCabinet',
                                    onClick: () => {
                                        resetForm();
                                        setPrescriptionFlowType({ flowType: 'Transfer' });
                                        navigate('/secure/prescription');
                                    }
                                }
                            ]}
                        />

                        {ENABLE_AUTO_REFILL && displayAutoRefillBanner && <AutoRefillBanner />}

                        <AccountBalance variant="banner" />

                        <PrescriptionsList pillImage={pillImage} />
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <NewMedicineCabinetCart />
                    </Col>
                </Row>
            </PageSection>
        </NewMedicineCabinetLayout>
    );
};

export default NewMedicineCabinet;

export const query = graphql`
    query NewMedicineCabinetData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
